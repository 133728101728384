body {
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #343719 black;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  padding: 1px !important;
  background: #1e2b09;

}

*::-webkit-scrollbar-track {
  background: #4f7019;
  border-radius: 10px;

}

body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #1e2b09;
}
 
body::-webkit-scrollbar-thumb {
  background-color: #1e2b09;
}
